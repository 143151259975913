<template>
  <div id="finance">
    <div class="fin_part1">
      <div class="part1_content flex_col">
        <div class="top flex">
          <img src="@/assets/images/finance/part1.png" alt="" />
          <div class="top_content flex_col">
            <div class="title flex_col">
              <span class="title queue-bottom">中小企业金融</span>
              <span class="title queue-bottom">服务专区</span>
            </div>
            <div class="title_content queue-bottom">
              金融服务专区，帮助企业缩短与银行的沟通路径，提高企业办事效率。
            </div>
            <ul class="flex">
              <li class="flex_col queue-bottom">
                <img src="@/assets/images/finance/crown.png" alt="" />
                <span>银行专属定制产品</span>
              </li>
              <li class="flex_col queue-bottom">
                <img src="@/assets/images/finance/well.png" alt="" />
                <span>客户经理优质服务</span>
              </li>
              <li class="flex_col queue-bottom">
                <img src="@/assets/images/finance/money.png" alt="" />
                <span>贷款惠企补贴推送</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom">
          <baskMask />
        </div>
      </div>
    </div>
    <div class="fin_part2">
      <div class="part2_content flex_col">
        <span class="base_font queue-bottom" ref="part2">专属银行客户经理 一对一服务</span>
        <img src="@/assets/images/finance/part2.png" alt="" />
      </div>
    </div>
    <div class="fin_part3">
      <div class="part3_content flex_col">
        <span class="base_font queue-bottom" ref="part3_1">融资、开户、理财 </span>
        <span class="base_font queue-bottom" ref="part3_2">为企业专属打造银行专区</span>
        <img src="@/assets/images/finance/part3.png" alt="" />
      </div>
    </div>
    <div class="fin_part4">
      <div class="part4_content flex">
        <div class="content">
          <span ref="part4" class="queue-bottom">智能推送贷款 惠企补贴</span>
        </div>
        <img src="@/assets/images/finance/part4.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import baskMask from '@/common/baseMask.vue'

export default {
  components: {
    baskMask
  },
  data () {
    return {
    }
  },
  computed: {},
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      if (e.target.documentElement.scrollTop >= 530) {
        if (this.$refs.part2) {
          this.$refs.part2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 1500) {
        if (this.$refs.part3_1 && this.$refs.part3_2) {
          this.$refs.part3_1.style.display = 'block'
          this.$refs.part3_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 2580) {
        console.log('xx')
        if (this.$refs.part4) {
          this.$refs.part4.style.display = 'block'
        }
      }
    }
  }
}

</script>{
<style lang='scss' scoped>
#finance {
  width: 100%;
  min-width: 1200px;
  .fin_part1 {
    padding-top: 134px;
    width: 100%;
    background: url(~assets/images/finance/bg1.png) no-repeat 50% 50%;
    background-size: cover;
    .part1_content {
      width: 1200px;
      margin: auto;
      .top {
        margin-left: 28px;
        img {
          width: 444px;
          height: 650px;
        }
        .top_content {
          align-items: flex-end;
          flex: 1;
          .title {
            align-items: flex-end;
            span {
              font-size: 67px;
              font-weight: bold;
              line-height: 81px;
              color: #dbbb95;
              letter-spacing: 1px;
            }
          }
          .title_content {
            text-align: right;
            margin-top: 35px;
            font-size: 26px;
            font-weight: 400;
            line-height: 39px;
            color: #ffffff;
            max-width: 590px;
          }
          ul {
            margin-top: 45px;
            li {
              align-items: center;
              img {
                width: 85px;
                height: 85px;
              }
              span {
                margin-top: 33px;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                color: #999999;
              }
            }
            li:not(:last-of-type) {
              margin-right: 72px;
            }
          }
        }
      }
      .bottom {
        background: #161616;
        margin-top: -45px;
      }
    }
  }
  .fin_part2 {
    width: 100%;
    background: url(~assets/images/finance/bg2.jpg) no-repeat 50% 50%;
    background-size: cover;
    margin-top: -170px;
    padding-top: 293px;
    .part2_content {
      width: 1200px;
      margin: auto;
      align-items: center;
      span {
        display: none;
        max-width: 610px;
        text-align: center;
      }
      img {
        margin-top: 34px;
        width: 1115px;
        height: 557px;
      }
    }
  }
  .fin_part3 {
    width: 100%;
    background: url(~assets/images/finance/bg3.png) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 133px;
    .part3_content {
      align-items: center;
      width: 1200px;
      margin: auto;
      span {
        display: none;
        max-width: 804px;
      }
      img {
        margin-top: 67px;
        width: 847px;
        height: 656px;
      }
    }
  }
  .fin_part4 {
    width: 100%;
    background: #fff;
    .part4_content {
      padding-top: 118px;
      width: 1200px;
      margin: auto;
      justify-content: center;
      img {
        margin-left: 102px;
        width: 557px;
        height: 812px;
      }
      .content {
        padding-top: 67px;
        width: 402px;
        span {
          display: none;
          font-size: 67px;
          font-weight: 400;
          line-height: 87px;
          color: #333333;
          max-width: 402px;
        }
        p {
          margin-top: 20px;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #333333;
          max-width: 380px;
        }
      }
    }
  }
}
</style>
